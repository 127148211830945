body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
    z-index: 1000;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1000;
}

.overlay {
    background: rgba(49,49,49,0.8);
    z-index: 1000;
}
.modal-content {
    position: absolute;
    top: 40%;
    left: 49%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background:transparent;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 700px;
    
    margin-top: 60px;
    z-index: 1000;
}

/*.modal-content {
    position: absolute;
    top: 40%;
    left: 44%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background:transparent;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 700px;
    min-width: 100px;
    margin-top: 60px;
    z-index: 1000;
}*/

.close-modal {
    position: absolute;
    top: 10px;
    right: 25px;
    padding: 3px 5px;
    z-index: 1000;
}



.imgw{
    width: 1000vw;
    max-height: 600px;
    max-width: 800px;
     z-index: 10000;
}

@media only screen and (max-width: 800px) {
    .imgw{
    width: 1000vw;
    max-height: 600px;
    max-width: 400px;
     z-index: 10000;
    }
  }
.imgw{
    width: 1000vw;
    max-height: 600px;
    max-width: 400px;
     z-index: 10000;
}