table {
  
/*  width: 500px;*/
  height: 500px;
  border-collapse: collapse;
            width: 90%;
  
}
  
tr:nth-child(odd) {
            background-color: #7e7748;
            color: white;

        }
tr:nth-child(even) {
            background-color: white;
            color: #7e7748;
        }
  
td {
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 1rem 0.7rem;
  font-size: 30px;
  min-height: 36px;
  font-family: 'OFL-Sorts';
  font-weight: 200;
  height: 50px;

}
th {
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 1rem 0.7rem;
  font-size: 30px;
  min-height: 36px;
  font-family: 'OFL-Sorts';
  font-weight: 200;
  height: 50px;
  overflow: hidden;

}


th:after {
  content: "";
  display: inline-block;
  height: 0.4em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 3px solid white;
}

th:before {
  content: "";
  display: inline-block;
  height: 0.4em;
  vertical-align: bottom;
  width: 100%;
  margin-left: -100%;
  margin-right: 10px;
  border-top: 3px solid white;
}